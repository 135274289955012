import { observer } from "mobx-react";
import React, {useEffect, useRef} from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from '@carfax-stencils/modal'
import Store from "../../../stores";
import "../../../styles/main.scss";
import Button from "@carfax-stencils/button";
import "./ModifyUserModal.scss";
import TextInput from "@carfax-stencils/input-text";
import {Location, Lock, Search} from "@carfax-stencils/icon";
import {RadioGroup, RadioInput} from "@carfax-stencils/input-radio-group";
import {Tab, TabList, TabPanel, Tabs} from "@carfax-stencils/tabs";
import {userDetailTabs} from "../../../types/profile/enums/userDetailTabs";
import {Table, TBody, TD, TH, THead, TR} from "@carfax-stencils/table";
import {UserOption} from "../../../types/acctMgmt/userOption";
import {CheckboxToggle} from "@carfax-stencils/input-checkbox";
import {UserRole} from "../../../types/acctMgmt/enums/userRole";


export default observer(() => {

    const rootStore = Store.root.root;
    const modifyUserStore = rootStore.acctMgmtStore.modifyUserStore;

    return (
        <Modal
            id="modify-user-modal"
            isVisible={true}
            onClose={modifyUserStore.handleModifyUserCancel}
        >
            <ModalHeader>
                    <div id='modify-user-modal-header'  className="modify-user-header-title-div"> Modify User</div>
            </ModalHeader>
            <ModalBody className={"modify-user-modal-body"}>
                <Tabs className={"modify-user-tabs"}
                      id="modify_user-layout-tabs-id"
                      selected={modifyUserStore.currentModalTab}
                      onSelect={(index) => {modifyUserStore.setCurrentModalTab(index);
                      }}
                      theme={"bar"}
                      size="full-width"
                      hideTopBorder
                >
                    <TabList>
                        <Tab className="modify-user-tab-item">{userDetailTabs[0]}</Tab>
                        <Tab className="modify-user-tab-item">{userDetailTabs[1]}</Tab>
                        <Tab className="modify-user-tab-item">{userDetailTabs[2]}</Tab>
                    </TabList>
                    <TabPanel>{modifyUserStore.currentModalTab === 0 && <BasicUserInfoSection />}</TabPanel>
                    <TabPanel>{modifyUserStore.currentModalTab === 1 && <EmailPreferencesSection />}</TabPanel>
                    <TabPanel>{modifyUserStore.currentModalTab === 2 && <AssociatedLocationSection />}</TabPanel>
                </Tabs>
            </ModalBody>
            <ModalFooter>
                <Button
                    id="modify-user-modal-save-button"
                    theme={"blue"}
                    size="large"
                    disabled={!modifyUserStore.determineUserChanges()}
                    onClick={modifyUserStore.handleModifyUserSave}
                >
                    Save Updates
                </Button>
                <Button
                    id="modify-user-modal-cancel-button"
                    theme={"black"}
                    size="large"
                    onClick={modifyUserStore.handleModifyUserCancel}
                >
                    Cancel Changes
                </Button>
            </ModalFooter>
        </Modal>
    )

});

const BasicUserInfoSection = observer(() => {

    const rootStore = Store.root.root;
    const modifyUserStore = rootStore.acctMgmtStore.modifyUserStore;

    return (
        <div id="basic-user-info-div" className="basic-user-info">
            <div id="basic-user-info-row1-div" className="basic-user-info__flex">
                <div id="basic-user-info-first-name-div" className="basic-user-info__first">
                    <div id="basic-user-info-first-name-label" className="left-padding basic-user-info-label">
                        First Name
                    </div>
                    <TextInput
                        id="basic-user-info-first-name"
                        value={modifyUserStore.editingUser?.firstName}
                        onChange={modifyUserStore.basicUserInfoFirstNameHandler}
                        placeholder={"First Name"}
                    />
                </div>
                <div id="basic-user-info-last-name-div" className="basic-user-info__last">
                    <div id="basic-user-info-last-name-label" className="left-padding basic-user-info-label">
                        Last Name
                    </div>
                    <TextInput
                        id="basic-user-info-last-name"
                        value={modifyUserStore.editingUser?.lastName}
                        onChange={modifyUserStore.basicUserInfoLastNameHandler}
                        placeholder={"Last Name"}
                    />
                </div>
            </div>
            <div id="basic-user-info-row2-div" className={"basic-user-info__flex"}>
                <div id="basic-user-info-email-div" className="basic-user-info__email">
                    <div id="basic-user-info-email-label" className="left-padding basic-user-info-label">
                        Email
                    </div>
                    <TextInput
                        id="basic-user-info-email"
                        icon={<Lock className='padlock-icon' size={24} /> }
                        disabled={true}
                        value={modifyUserStore.editingUser?.email}
                    />
                </div>
            </div>
            <hr id="basic-user-info-separator-line" className="modify-user-separator-line"/>
            <p id="basic-user-info-user-access-settings" className="modify-user-settings-header">
                User Access Settings
            </p>
            <RadioGroup
                id="basic-user-info-role"
                value={modifyUserStore.editingUser?.role}
                onChange={modifyUserStore.basicUserInfoRoleHandler}
                name="role-radio-group"
            >
                <RadioInput value={UserRole.ADMIN}>
                    <div id="basic-user-info-role-admin-div">
                        <div className="basic-user-info-role-name">Admin User</div>
                        <p>Can manage dealership users and has access to CARFAX tools</p>
                    </div>
                </RadioInput>
                <RadioInput value={UserRole.USER}>
                    <div id="basic-user-info-role-basic-div">
                        <div className="basic-user-info-role-name">Basic User</div>
                        <p>Has access to CARFAX tools</p>
                    </div>
                </RadioInput>
                <RadioInput value={UserRole.SALESPERSON}>
                    <div id="basic-user-info-role-salesperson-div">
                        <div className="basic-user-info-role-name">Salesperson</div>
                        <p>Has access to run VHRs</p>
                    </div>
                </RadioInput>
                <RadioInput value={UserRole.ACCOUNTING}>
                    <div id="basic-user-info-role-accounting-div">
                        <div className="basic-user-info-role-name">Accounting</div>
                        <p>Has access to view billing information and download Co-Op packets</p>
                    </div>
                </RadioInput>
            </RadioGroup>
        </div>
    );
});

const EmailPreferencesSection = observer(() => {
    const modifyUserStore = Store.root.root.acctMgmtStore.modifyUserStore;

    return (
        <div id="email-preferences-div" className={"email-preferences"}>
            <div id="email-preferences-listing-div" className="email-preferences__todo">
                <p id="email-preferences-table" hidden={true}>Opt in or out of email notifications.</p>
                <Table aria-describedby="email-preferences-table" className="email-preferences-table" disableHoverShadow={true}>
                    <THead className="email-preferences-table__heading">
                        <TH className="email-preferences-table__heading__TH" width={120}>Email Notification Types</TH>
                        <TH className="email-preferences-table__heading__TH" width={20}>Opted In</TH>
                    </THead>
                    <TBody>
                        {modifyUserStore.editingUser?.userOptions.filter(
                            (userOption) => {return ((modifyUserStore.editingUser.role === UserRole.ADMIN ||  modifyUserStore.editingUser.role === UserRole.USER) &&
                                modifyUserStore.businessUserStore.isDealer() && modifyUserStore.businessUserStore.isEnhanced())
                                || userOption.optionName !== 'PriceChangeEmail' }
                        ).map((option: UserOption, index) => (
                            <TR className="email-preferences-table__row" key={option.optionsId + "_" + option.optionName + "_" + index}>
                                <TD className="email-preferences-table__column">{option.description}</TD>
                                <TD className="email-preferences-table__column">
                                    <CheckboxToggle
                                        id="email-preferences-optIn-toggle"
                                        checked={option.value}
                                        onChange={event => {
                                            modifyUserStore.onToggleEmailOption(option, event.target.checked)
                                        }}
                                    />
                                </TD>
                            </TR>
                        ))}
                    </TBody>
                </Table>
            </div>
        </div>
    );
});

const EmptyAssocLocationSection = () => {

    return (
        <div id="modify-associated-locations-empty-div" className="modify-associated-locations__empty-body">
            <div className="modify-associated-locations__empty-header">
                No Associated Locations Available
            </div>
            <div className="modify-associated-locations__empty-text">
                This user does not have associated locations because
                their default location is a single store dealership.
            </div>
        </div>
    )
}

const AssociatedLocationSection = observer(() => {

    const locationDivRef = useRef<HTMLDivElement>(null);
    const modifyUserStore = Store.root.root.acctMgmtStore.modifyUserStore;

    useEffect(() => {
        if(modifyUserStore.focusOnAssociatedModal && locationDivRef?.current) {
            locationDivRef?.current?.focus();
            modifyUserStore.focusOnAssociatedModal = false;
        }
    });

    return (
        <div id="modify-associated-locations-div">
            { modifyUserStore.associatedLocations?.length > 0 &&
            <div id="modify-associated-locations-body-div"  className="modify-associated-locations__body">
                <div className="modify-associated-locations__search">
                    <div id="modify-user-location-location-search-label-div" className="modify-associated-locations__search-label" tabIndex={-1} ref={locationDivRef}>
                        <Location size={24}/>
                        <span className="modify-associated-locations__search-count">
                            {
                                modifyUserStore.editingUser?.accessLocations
                                    ? modifyUserStore.editingUser.accessLocations.length
                                    : 0
                            } Store(s) Selected
                        </span>
                    </div>
                    <TextInput
                        id="modify-location-search"
                        icon={<Search onClick={() => modifyUserStore.searchLocations()} className='modify-associated-locations__search-icon' size={23} /> }
                        name="dealerName"
                        placeholder="Search by Dealer Name..."
                        className="modify-associated-locations__text-input"
                        required={true}
                        isSmall={true}
                        type="text"
                        value={modifyUserStore.associatedLocationFilterValue}
                        onChange={modifyUserStore.onFilterChange}
                    />
                </div>
                <Table aria-label="Associated Locations">
                    <THead onSelectAll={modifyUserStore.handleLocationSelectAll}
                           selectedAll={modifyUserStore.isAllSelected()}>
                        <TH width={200}>
                            Dealer Name
                        </TH>
                        <TH width={70}>
                            Status
                        </TH>
                        <TH width={100}>
                            Location
                        </TH>
                    </THead>
                    <TBody>
                        {modifyUserStore.filteredAssociatedLocations.map((loc, key) => (
                            <TR key={key}
                                onSelect={() => {modifyUserStore.handleLocationSelect(loc)}}
                                selected={loc.selected}>
                                <TD><strong>{loc.companyName}</strong> - {loc.compCode}</TD>
                                <TD>
                                    <div className="assc-loc__user-location-status">
                                        <div className={loc.status === "Active" ? "assc-loc__dealer-status-active" : "assc-loc__dealer-status-inactive"}></div>
                                        <div className="assc-loc__location-status-text">{loc.status}</div>
                                    </div>
                                </TD>
                                <TD>{loc.address.city}, {loc.address.state}</TD>
                            </TR>
                        ))}
                    </TBody>
                </Table>

            </div> }
            { modifyUserStore.associatedLocations?.length === 0 &&
            <EmptyAssocLocationSection/>}
        </div>
    );
});